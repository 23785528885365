import React, { ReactElement, useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { sortBy, indexOf } from 'lodash'
import Layout from '../../layouts/Layout/Layout'
import './ContactUsShowroom.scss'
import ArticleLayout, {
  RecentArticleTypes,
} from '../../layouts/ArticleLayout/ArticleLayout'
import CONTACT_US_NAVIGATION from '../../data/contact-us-navigation.json'
import ContactUsBanner from '../../assets/images/contact-us-banner.png'
import { toKebabCase } from '../../services/caseTransform'
import Seo from '../../components/Seo/Seo'
import { MediaTypes } from '../../components/MediaGallery/MediaGallery'

import ContactUsLayout from '../../layouts/ContactUsLayout/ContactUsLayout'

type ContactUsShowroomPropTypes = {
  data: {
    allShowroom: {
      nodes: {
        showRoomTitle: string
        seoShowroomTitle: string
        seoShowroomDescription: string
      }[]
    }
    showroomData?: {
      showRoomTitle: string
      seoShowroomTitle: string
      seoShowroomDescription: string
      showroomList?: {
        items?: {
          address: string
          telephoneHotline: string
          telephoneNumber: string
          title: string
        }[]
      }
      images: MediaTypes[]
    }
    recentArticles: {
      nodes: RecentArticleTypes[]
    }
  }
}

const ContactUsShowroom = ({
  data: {
    allShowroom: { nodes: allShowroom },
    showroomData,
    recentArticles: { nodes: recentArticles },
  },
}: ContactUsShowroomPropTypes): ReactElement => {
  const [contentNavigation, setContentNavigation] = useState({
    navigationPrefix: 'contact-us/showroom',
    navigations: allShowroom.map((showroom, index) => ({
      url: toKebabCase(showroom.showRoomTitle),
      label: showroom.showRoomTitle,
    })),
  })

  // Set Order here (Pascal Case)
  const order = [
    'Metro Manila',
    'South Luzon',
    'Visayas',
    'Davao',
    'North Luzon',
    'Mindanao',
  ]
  const navOrdered = sortBy(allShowroom, [
    function (showroom): any {
      return indexOf(order, showroom.showRoomTitle)
    },
  ])

  const getBanner = (): string => {
    return showroomData?.images ? showroomData?.images[0]?.url : ContactUsBanner
  }

  const initialShowroom = allShowroom[0]
  const showroomUrl = toKebabCase(initialShowroom.showRoomTitle)

  const contactUsNavigation = CONTACT_US_NAVIGATION.map((item) => {
    return {
      url: item?.url,
      label: item.label,
      items: item.items?.map((val) => {
        return {
          url:
            val.url === 'contact-us/showroom'
              ? `contact-us/showroom/${showroomUrl}`
              : val.url,
          label: val.label,
        }
      }),
    }
  })

  useEffect(() => {
    setContentNavigation({
      navigationPrefix: 'contact-us/showroom',
      navigations: navOrdered.map((showroom, index) => ({
        url: toKebabCase(showroom.showRoomTitle),
        label: showroom.showRoomTitle,
      })),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout>
      <Seo
        slug={`contact-us/showroom/${toKebabCase(showroomData?.showRoomTitle)}`}
        ogMetaData={{
          title: showroomData?.seoShowroomTitle
            ? showroomData.seoShowroomTitle
            : 'Contact Us | Showroom',
          description: showroomData?.seoShowroomDescription
            ? showroomData?.seoShowroomDescription
            : '',
          image: '',
        }}
        jsonData={{
          url: `${
            process.env.GATSBY_SITE_URL
          }/contact-us/showroom/${toKebabCase(showroomData?.showRoomTitle)}/`,
          keywords:
            'properties, condo, philippines, house and lot, avidaland, avida, apartment, showroom, avida showroom, unit showroom, condo showroom',
        }}
        title={
          showroomData?.seoShowroomTitle
            ? showroomData.seoShowroomTitle
            : 'Contact Us | Showroom'
        }
      />
      <ContactUsLayout className="">
        <ArticleLayout
          title="Contact Us"
          asideNavItems={contactUsNavigation}
          asideArticleTitle="News & Events"
          contentNavigation={contentNavigation}
          recentArticles={recentArticles}
          contentClassName="contact-us-showroom"
        >
          <LazyLoadImage
            src={getBanner()}
            alt="banner image"
            className="contact-us-showroom-banner"
          />
          <div className="contact-us-showroom-content-container">
            {showroomData?.seoShowroomTitle ? (
              <h1 style={{ display: 'none' }}>
                {showroomData?.seoShowroomTitle}
              </h1>
            ) : null}
            <h2 className="contact-us-showroom-title">Showroom and Offices</h2>
            {!!showroomData && (
              <>
                <h3 className="contact-us-showroom-location">
                  {showroomData.showRoomTitle}
                </h3>
                <ul className="contact-us-showroom-location-list">
                  {showroomData.showroomList?.items?.map((showroom) => (
                    <li key={showroom.title}>
                      <h4>{showroom.title}</h4>
                      <p>{showroom.address}</p>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </ArticleLayout>
      </ContactUsLayout>
    </Layout>
  )
}

export default ContactUsShowroom

export const pageQuery = graphql`
  query ShowroomQuery($id: String!) {
    allShowroom(filter: { type: { eq: "" } }) {
      nodes {
        showRoomTitle
        seoShowroomTitle
        seoShowroomDescription
      }
    }
    showroomData: showroom(id: { eq: $id }) {
      ...ShowroomFields
      seoShowroomTitle
      seoShowroomDescription
    }
    recentArticles: allNewsAndEvents(limit: 3) {
      nodes {
        ...NewsAndEventsCardFields
      }
    }
  }
`
